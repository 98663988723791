<template>
  <div>
    <v-app-bar app flat outlined color="transparent" hide-on-scroll>
      <v-btn text to="/" class="link-font">
        <h3 class="mr-2">11M</h3>
      </v-btn>

      <v-spacer></v-spacer>
      <v-col class="d-flex mr-2">
        <v-row justify="end" class="flex-nowrap">
          <v-btn text to="/info" class="link-font">
            <h3 class="mr-2">+INFO</h3>
          </v-btn>
          <!-- Audio player theme -->
          <v-btn
            text
            class="link-font"
            @click.native="playing ? pause() : play()"
            :disabled="!loaded"
          >
            <v-icon v-if="!playing || paused">{{ playIcon }}</v-icon>
            <v-icon v-else>{{ pauseIcon }}</v-icon>
          </v-btn>
          <audio
            id="player"
            ref="player"
            v-on:ended="ended"
            v-on:canplay="canPlay"
            :src="file"
          ></audio>
          <!-- End Audio player theme -->
        </v-row>
      </v-col>
    </v-app-bar>
  </div>
</template>

<script>
const formatTime = (second) =>
  new Date(second * 1000).toISOString().substr(11, 8);

export default {
  name: "NavAudio",
  props: {
    ended: {
      type: Function,
      default: () => {},
    },
    canPlay: {
      type: Function,
      default: () => {},
    },

    playIcon: {
      type: String,
      default: "mdi-play",
    },
    pauseIcon: {
      type: String,
      default: "mdi-pause",
    },
  },
  data() {
    return {
      file: "https://firebasestorage.googleapis.com/v0/b/once-manolo-v2-2.appspot.com/o/g-cortina.mp3?alt=media&token=538ba698-8ebc-4e0c-bad7-1b1d51a1ca17",
      firstPlay: true,
      loaded: false,
      playing: false,
      paused: false,
    };
  },

  methods: {
    play() {
      if (this.playing) return;
      // eslint-disable-next-line no-unused-vars
      this.audio.play().then((_) => (this.playing = true));
      this.paused = false;
    },
    pause() {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    },
    _handleLoaded: function () {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {};
            this.audio.currentTime = 0;
            this.totalDuration = parseInt(this.audio.duration);
            this.loaded = true;
          };
        } else {
          this.totalDuration = parseInt(this.audio.duration);
          this.loaded = true;
        }

        if (this.autoPlay) this.audio.play();
      } else {
        throw new Error("Failed to load sound file");
      }
    },
    // eslint-disable-next-line no-unused-vars
    _handlePlayingUI: function (e) {
      this.audio.volume = this.playerVolume;
      this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      this.currentTime = formatTime(this.audio.currentTime);
      this.playing = true;
    },
    _handlePlayPause: function (e) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (
        e.type === "pause" &&
        this.paused === false &&
        this.playing === false
      ) {
        this.currentTime = "00:00:00";
      }
    },
    _handleEnded() {
      this.paused = this.playing = false;
    },
    init: function () {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
    },
  },
  mounted() {
    this.audio = this.$refs.player;
    this.init();
  },
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  },
};
</script>

<style scoped>
.link-font:hover {
  color: #1b5e20;
}

.link-font:before {
  background-color: transparent !important;
}

#player {
  display: none;
}
</style>
